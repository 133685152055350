<template>
	<div>
		<a-modal v-model:visible="visible" >
			<template #title>
				<modalHeader title="公告详情"></modalHeader>
			</template>
			<template #footer>
				<a-button @click="close">关闭</a-button>
			</template>
			<div class="ui-header">
				<!-- 关于开展2023年度国有资产盘点工作的通知 -->
				{{modelRef.title}}
			</div>
			<div>
				<div style="margin-top: 14px;" v-html="modelRef.content"></div>
			</div>
			<div v-if="modelRef.imgUrl">
				附件：
				<a-button type="link" size="small" @click="onOpenFile">《附件文件》
						<!-- <a :href="modelRef.imgUrl">《附件文件》</a> -->
				</a-button>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import modalHeader from '@/components/modalHeader/index.vue';
	export default {
		components: { modalHeader },
		data() {
			return {
				visible: false,
				modelRef: {}
			}
		},
		methods: {
			open(e) {
				if (e && e.id) {
					this.modelRef = {
						id: e.id,
						title: e.title,
						content: e.content,
						type: e.type,
						imgUrl: e.imgUrl
					}
					this.isEdit = true;
				} else {
					this.modelRef = {};
					this.isEdit = false;
				}
				this.visible = true;
			},
			close() {
				this.visible = false;
			},
			onOpenFile() {
				window.open(this.modelRef.imgUrl)
			}
		}
	}
</script>

<style scoped>
</style>