<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button>
					<exportModal class="ui-exportBrn" v-permission="['pc_system_notice_export']" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1">
						<a-button>
							<template #icon><Icon icon="UploadOutlined"/></template>
							导出</a-button>
					</exportModal>
					<exportModal class="ui-exportBrn" v-permission="['pc_system_notice_export']" style="margin-right: 16px;" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0">
						<a-button>
							<template #icon><Icon icon="LogoutOutlined"/></template>
							导出全部</a-button>
					</exportModal>
					<a-button @click="onSearch">
						<template #icon><Icon icon="ReloadOutlined"/></template>
						刷新</a-button>
					<a-button @click="onSort">
						<template #icon><Icon icon="OrderedListOutlined"/></template>
						排序</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right;">
					<a-button v-permission="['pc_system_notice_add']" type="primary" @click="onAdd">新增公告</a-button>
				</a-col>
			</a-row>
			
			<div v-show="isSearch">
				<a-form class="label-width ui-form" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="标题" class="ui-form__item">
						<a-input v-model:value="formModal.title" placeholder="请输入标题"></a-input>
					</a-form-item>
					
					<a-form-item label="发布时间" class="ui-form__item">
						<a-range-picker v-model:value="clearTime" show-time></a-range-picker>
					</a-form-item>
					<a-form-item class="ui-form__item">
						<div style="text-align: center;">
							<a-button @click="onReset">重置</a-button>
							<a-button type="primary" @click="onSearch">搜索</a-button>
						</div>
					</a-form-item>
				</a-form>
			</div>
			
			<div v-show="isSort" class="ui-form" style="padding-bottom: 20px;">
				<a-row style="align-items: center;">
					<a-col :span="18">
						<a-checkbox-group v-model:value="sortList">
							<a-checkbox :value="'pt.title'">标题</a-checkbox>
							<a-checkbox :value="'pt.create_time'">发布时间</a-checkbox>
						</a-checkbox-group>
					</a-col>
					<a-col :span="6" style='text-align: right;'>
						<a-button type="primary" @click="onSortClick('asc')">升序</a-button>
						<a-button type="primary" @click="onSortClick('desc')">降序</a-button>
						<a-button type="primary" @click="onResetSortClick()">重置</a-button>
					</a-col>
				</a-row>
			</div>
			
			<div style="margin-top: 20px;">
				<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" rowSelectionType="checkbox" :searchData="searchData" rowKey="id" @rowSelected="rowSelected" :scroll="{ x: 1500 }" bordered draggable>
					<template #bodyCell="{ column, record, index, pagination }">
						<template v-if="column.key === 'title'">
							<a-tooltip :title="record.title">
								<div class="ui-aBtn" @click="onDetail(record)">{{record.title}}</div>
							</a-tooltip>
						</template>
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['pc_system_notice_edit']" type="link" size="small" @click="onEdit(record)">编辑</a-button>
							<span></span>
							<a-button v-permission="['pc_system_notice_delete']" type="link" size="small" @click="onDelete(record)" danger>删除</a-button>
						</template>
					</template>
				</c-Table>
				<!-- <a-table rowKey="id" class="table"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{ x: 1500 }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'title'">
							<a-tooltip :title="record.title">
								<div class="ui-aBtn" @click="onDetail(record)">{{record.title}}</div>
							</a-tooltip>
						</template>
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['pc_system_notice_edit']" type="link" size="small" @click="onEdit(record)">编辑</a-button>
							<span></span>
							<a-button v-permission="['pc_system_notice_delete']" type="link" size="small" @click="onDelete(record)" danger>删除</a-button>
						</template>
					</template>
				</a-table> -->
			</div>
		</a-spin>
		<saveModal ref="saveModalRef" @refresh="onReset"></saveModal>
		<detailModal ref="detailModalRef"></detailModal>
	</div>
</template>

<script>
	import { getPlatText, getPlatTextDelete } from '@/service/modules/system.js';
	import { Icon } from '@/components/icon/icon.js';
	import saveModal from './components/saveModal.vue';
	import detailModal from './components/detailModal.vue';
	import cTable from '@/components/cTable/index.vue';
	import exportModal from '@/components/exportModal/exportModal.vue';
	export default {
		components: { Icon, saveModal, detailModal, cTable, exportModal },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				isExpand: false,
				searchData: {},
				formModal: {
					type: 2
				},
				getDataFun: getPlatText,
				clearTime: [], // 存储时间 用来清空重置
				list: [],
				type: 'noticeMangerStrategy', // 导出类型
				role: 1, // 导出角色
				condition: '3', // 导出条件
				selIdsData: {},
				sortList:[],
				columns: [{
					title: '行号',
					align: 'center',
					key: 'index',
					width: 100
				}, {
					title: '标题',
					align: 'center',
					key: 'title',
					// width: 200
				}, 
				// {
				// 	title: '发布单位',
				// 	align: 'center',
				// 	dataIndex: "companyId"
				// }, 
				{
					title: '发布时间',
					align: 'center',
					key: 'time'
				}, {
					title: '操作',
					align: 'center',
					key: 'action',
					fixed: 'right',
					width: 150
				}],
			}
		},
		created() {
			this.onSearch()
		},
		methods: {
			// async getData() {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await getPlatText({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			...this.searchData
			// 		});
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			console.log("notice",ret)
			// 			this.list = ret.data.list;
			// 			this.pagination.total = ret.data.totalCount;
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	}
			// },
			onReset() {
				this.$refs.formRef.resetFields();
				this.formModal = {
					type: 2
				}
				this.clearTime = []
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));
				if (this.clearTime && this.clearTime.length) {
					this.searchData.startCreateTime = this.clearTime[0].unix();
					this.searchData.endCreateTime = this.clearTime[1].unix();
				}
				// this.pagination.current = 1;
				// this.getData();
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			onAdd() {
				this.$refs.saveModalRef.open();
			},
			onEdit(item) {
				this.$refs.saveModalRef.open(item);
			},
			onDetail(item) {
				this.$refs.detailModalRef.open(item);
			},
			onDelete(item) { 
				this.$confirm({
					title: '提示',
					content: "确定删除吗？",
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await getPlatTextDelete({
								id: item.id,
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功');
								// this.getData();
								this.onSearch()
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			rowSelected(item) {
				this.selIdsData.ids = item.keys.join(',')
			},
			onChangeFun(date, dateString) { // 时间变化的回调
			  this.clearTime = date
			  let starDate = new Date(dateString[0])
			  let endDate = new Date(dateString[1])
			  this.formModal.startCreateTime = starDate.valueOf()/1000
			  this.formModal.endCreateTime = endDate.valueOf()/1000
			},
			onSortClick(type){
				let list = [];
				this.sortList.forEach(element => {
					list.push({key:element,sort:type});
				});
				this.formModal.sort = list;
				console.log(this.formModal);
				this.onSearch();
			},
			onResetSortClick(){
				this.sortList = [];
				this.formModal.sort = undefined;
				this.onSearch();
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
	.ui-aBtn {
		color: #1890ff;
		/* width: 250px; */
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
</style>