<template>
	<div>
		<a-modal v-model:visible="visible" width="700px" @cancel="close" @ok="onSubmit" >
			<template #title>
				<modalHeader :title="isEdit ? '编辑公告' : '新增公告'"></modalHeader>
			</template>
			
			<div>
				<a-form ref="formRef" :model="modelRef" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
					<a-form-item label="标题" name="title" :rules="[{ required: true, message: '请输入公告标题!' }]">
						<a-input v-model:value="modelRef.title" placeholder="请输入公告标题"></a-input>
					</a-form-item>
					
					<a-form-item label="公告内容" name="content" :rules="[{ required: true, message: '请输入公告内容!' }]">
						<editor v-model:value="modelRef.content"></editor>
					</a-form-item>
					<!-- <a-form-item label="请选择公告类型"  name="type" :rules="[{ required: true, message: '请选择公告类型!' }]">
						<a-select v-model:value="modelRef.type"  allow-clear style="width: 190px;">
							<a-select-option :value="2">公告</a-select-option>
						</a-select>
					</a-form-item> -->
					<a-form-item label="附件">
						<!-- <filesUpload v-model:value="modelRef.fileList" :maxSize="100"></filesUpload> -->
						<filesUpload v-model:value="fileList" :maxSize="100"></filesUpload>
						<p class="ui-upload__warn">注：建议上传pdf文件，文件大小不超过100M</p>
					</a-form-item>
				</a-form>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import { getPlatTextSave, getPlatTextUpdate } from '@/service/modules/system.js';
	import { Icon } from '@/components/icon/icon.js';
	import modalHeader from '@/components/modalHeader/index.vue';
	import editor from '@/components/editor/editor.vue';
	import filesUpload from '@/components/upload/filesUpload.vue';
	export default {
		components: { Icon, editor, modalHeader, filesUpload },
		data() {
			return {
				visible: false,
				isEdit: false,
				modelRef: {
					fileList: [],
					// type: 2
				},
				fileList: []
			}
		},
		methods: {
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					// let postData = this.$deepClone(this.modelRef)
					// postData.imgUrl = this.modelRef.fileList.toLocaleString()
					// postData = JSON.parse(JSON.stringify(postData));
					// this.modelRef.imgUrl = this.modelRef.fileList.toLocaleString()
					this.modelRef.imgUrl = this.fileList.toLocaleString()
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					postData.type = 2 // 公告类型 默认
					this.loading = true;
					try {
						let ret;
						if (this.isEdit) {
							ret = await getPlatTextUpdate(postData);
						} else {
							ret = await getPlatTextSave(postData);
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success(this.isEdit ? '编辑成功' : '新增成功');
							this.$emit('refresh');
							this.close();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			open(e) {
				if (e && e.id) {
					this.modelRef = {
						id: e.id,
						title: e.title,
						content: e.content,
						sort: e.sort,
						type: e.type,
						imgUrl: e.imgUrl
					}
					this.fileList = [e.imgUrl];
					this.isEdit = true;
				} else {
					this.modelRef = {
						// fileList: []
					};
					this.isEdit = false;
				}
				this.visible = true;
			},
			close() {
				this.visible = false;
				this.$refs.formRef.clearValidate();
				this.fileList = [];
				this.modelRef = {
					// fileList: []
				}
			}
		}
	}
</script>

<style scoped>
	.ui-upload__warn {
		margin-top: 6px;
		font-size: 12px;
		color: #999;
	}
</style>